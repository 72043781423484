import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#5E33E6"
            },
            dark: {
                primary: '#5E33E6',
            }
        },
    },
});
