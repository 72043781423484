import store from "@/store";
import { db, functions } from "@/firebase";
import { httpsCallable } from 'firebase/functions';
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";


export const createTeam = async (data) => {
    const companyId = store.state.auth.user.companyId;
    const companyName = store.state.auth.company.name;

    const user = {
        disabled: false,
        email: data.email,
        displayName: data.name,
        password: data.password,
        companyName: companyName,
        customClaims: {
            [data.role.value]: true,
            companyId: companyId,
            companyName: companyName,
            roleName: data.role.roleName
        },
    };

    console.debug("USER: ", user)

    try {
        const addMessage = httpsCallable(functions, "userCreate");
        const result = await addMessage(user)
        return { role: [data.role.value], ...result.data };
    } catch (error) {
        console.error("Error createTeam:", error);
        throw error;
    }
}

export const getAllTeam = async () => {
    const teamCol = collection(db, "users");
    const companyId = store.state.auth.user.companyId;

    try {
        const q = query(teamCol, where("company.uid", "==", companyId), orderBy("displayName"));
        const querySnapshot = await getDocs(q);

        return querySnapshot.docs.map(doc => ({ uid: doc.id, ...doc.data() }));
    } catch (error) {
        console.error("Error getAllTeam:", error);
        throw error;
    }
}

export const deleteItem = async (id) => {
    try {
        const deleteUser = httpsCallable(functions, "deleteUser");
        await deleteUser(id);
    } catch (error) {
        console.error("Error deleteItem:", error);
        throw error;

    }
}