<template>
  <v-navigation-drawer :width="width" :color="color" :value="value" @input="$emit('input', $event)"
    style="z-index: 998;" app>
    <template v-slot:prepend>
      <DrawerLogo class="mt-4 pl-1" @close="$emit('input', $event)" />

      <div class="px-4 pt-6">
        <DrawerScan v-if="user.scan" class="mt-6" />
      </div>
    </template>

    <div class="px-3">
      <DrawerNavigation />
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import { iconInfo } from '@/mixins/icons'

import DrawerLogo from './DrawerLogo.vue';
import DrawerScan from './DrawerScan.vue';
import DrawerNavigation from './DrawerNavigation.vue';

export default {
  props: ["value"],

  data: () => ({
    iconInfo: iconInfo,
  }),

  components: { DrawerLogo, DrawerScan, DrawerNavigation },

  computed: {
    ...mapState("auth", ["user", "company"]),

    width() {
      return this.$vuetify.breakpoint.mobile ? '80%' : '280';
    },

    color() {
      return this.$vuetify.theme.dark ? '#1b1b1b' : '#FFFFFF'
    }
  },
};
</script>