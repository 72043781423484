<template>
  <component :is="this.$route.meta.layout || 'div'">
    <router-view />
  </component>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    metaThemeColor: null,
    metaStatusBar: null,
  }),

  methods: {
    updateTheme(event) {
      const isDark = event.matches;
      this.$vuetify.theme.dark = isDark;
      this.updateStatusBarColor(isDark);
    },

    updateStatusBarColor(isDark) {
      if (this.metaThemeColor) {
        this.metaThemeColor.setAttribute("content", isDark ? "#272727" : "#ffffff");
      }
      if (this.metaStatusBar) {
        this.metaStatusBar.setAttribute("content", isDark ? "black-translucent" : "default");
      }
    }
  },

  mounted() {
    this.metaThemeColor = document.querySelector("meta[name=theme-color]");
    this.metaStatusBar = document.querySelector("meta[name=apple-mobile-web-app-status-bar-style]");

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    this.updateTheme(mediaQuery);
    mediaQuery.addEventListener("change", this.updateTheme.bind(this));
    this.$store.commit("set_desktop", this.$vuetify.breakpoint.lgAndUp);
  },

  destroyed() {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQuery.removeEventListener("change", this.updateTheme.bind(this));
  },
};
</script>

<style lang="scss">
@import './sass/overrides.scss';
</style>