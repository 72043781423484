import MainLayout from '../layouts/Panel.vue'

import ProfileView from '../views/ProfileView.vue'

export const ApplicationRoutes = [
    {
        path: '/profile',
        name: 'profile',
        component: ProfileView,
        meta: { 
            auth: true, 
            seller: true, 
            owner: true, 
            scan: true, 
            layout: MainLayout, 
            title: 'Perfil del Usuario' },
    }
]