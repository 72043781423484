<template>
    <h1 class="text-h5 font-weight-bold" v-if="!appMobile">
        <slot />
    </h1>
</template>

<script>
export default {
    name: 'PageTitle'
}
</script>