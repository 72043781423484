import { db, auth } from '@/firebase'
import { updateProfile, updatePassword } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import store from '@/store'

export const auth_get_user_profile = async () => {
    try {
        const user = auth.currentUser;
        if (user !== null) {
            const displayName = user.displayName;
            const email = user.email;
            const photoURL = user.photoURL;
            return { displayName, email, photoURL };
        } else {
            throw new Error('Usuario no autenticado')
        }
    } catch (error) {
        console.error('Error al obtener el perfil del usuario', error)
        throw error
    }
}

export const auth_update_user_profile = async (userProfile) => {
    try {
        const user = auth.currentUser;
        const userRef = doc(db, "users", user.uid);
        if (user !== null) {
            await updateProfile(user, userProfile);
            await updateDoc(userRef, { displayName: userProfile.displayName });
            store.commit('auth/SET_USER_NAME', userProfile.displayName);
        }
    } catch (error) {
        console.error('Error al actualizar el perfil del usuario', error)
        throw error
    }
}

export const auth_update_user_password = async (newPassword) => {
    try {
        const user = auth.currentUser;
        if (user !== null) {
            await updatePassword(user, newPassword);
            auth.signOut();
        }
    } catch (error) {
        console.error('Error al actualizar la contraseña del usuario', error)
        throw error
    }
}

export const auth_update_user_photo = async (photoURL) => {
    try {
        const user = auth.currentUser;
        if (user !== null) {
            const userRef = doc(db, "users", user.uid);
            await updateProfile(user, { photoURL: photoURL });
            await updateDoc(userRef, { photoURL: photoURL });
        } else {
            throw new Error('Usuario no autenticado')
        }
    } catch (error) {
        console.error('Error al actualizar la foto del usuario', error)
        throw error
    }
}