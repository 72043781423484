import store from "@/store";
import { db, auth } from '@/firebase';
import { signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

export const getCompanyData = async () => {
    const companyId = store.state.auth.user.companyId;

    try {
        const docRef = doc(db, "companies", companyId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            store.commit("auth/SET_COMPANY", data);
        } else {
            throw new Error("No such document!");
        }
    } catch (error) {
        console.error("getCompanyData", error);
        throw error;
    }
}

export const signOutAction = async () => {
    try {
        await signOut(auth);
        store.commit("auth/SET_USER", {});
        store.commit("auth/SET_COMPANY", {});
        store.commit("auth/set_role_producer", false);
        window.localStorage.removeItem("scanMode");
    } catch (error) {
        console.error("Error signing out:", error);
        throw error;
    }
}