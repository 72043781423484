

<template>
    <div :style="`position: relative; width: ${size}px; height: ${size}px; display: inline-block;`">
        <!-- avatar -->
        <v-avatar :size="size">
            <v-img :src="image" alt="Profile" cover />
        </v-avatar>
        <!-- actions -->
        <div class="d-flex justify-space-between" style="position: absolute; bottom: 0; width: 100%; margin-bottom: -5px;">
            <v-btn @click="triggerFileInput" color="primary" fab small :loading="loading" :disabled="loading">
                <v-icon>mdi-camera</v-icon>
                <input type="file" @change="handleFileChange" ref="fileInput" accept="image/*" style="display: none;">
            </v-btn>
            <v-btn @click="deleteImage" color="red" dark fab small :loading="loading" :disabled="loading">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import imageCompression from 'browser-image-compression';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { auth_update_user_photo } from '@/services/userProfile';

export default {
    name: 'UpdateImageProfile',
    props: ['userProfile', 'userID', 'size'],

    data() {
        return {
            image: '',
            loading: false
        }
    },
    watch: {
        userProfile: function (newProfile) {
            this.image = newProfile.photoURL ? newProfile.photoURL : 'https://placehold.co/100'
        }
    },
    methods: {
        triggerFileInput() {
            this.$refs.fileInput.click();
        },

        async handleFileChange(event) {
            const imageFile = event.target.files[0];

            console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
            console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

            const options = { maxSizeMB: 1, maxWidthOrHeight: 300, useWebWorker: true }

            try {
                const compressedFile = await imageCompression(imageFile, options);
                console.log('compressedFile instanceof Blob', compressedFile instanceof Blob);
                console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
                this.uploadFile(compressedFile);
            } catch (error) {
                console.log('error', error)
            }
        },

        async uploadFile(file) {
            this.loading = true;
            const storage = getStorage();
            const metadata = { contentType: 'image/jpeg' };

            const storageRef = ref(storage, 'profilePhotos/' + `${new Date().getTime()}_${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file, metadata);

            const fileURL = await uploadTask.then(snap => getDownloadURL(snap.ref));
            await auth_update_user_photo(fileURL);
            this.image = fileURL;
            this.loading = false;
        },

        async deleteImage() {
            this.image = '';
            await auth_update_user_photo('');
        }
    }
}
</script>
