<template>
    <v-app-bar class="rv-app-bar" :color="themeColor" v-if="$vuetify.breakpoint.mobile" flat app>
        <v-btn v-if="!backButton" @click="$router.back()" color="black" icon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-narrow-left">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l14 0" />
                <path d="M5 12l4 4" />
                <path d="M5 12l4 -4" />
            </svg>
        </v-btn>
        <v-toolbar-title class="title">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="$emit('input')" />
    </v-app-bar>
</template>

<script>

export default {
    data() {
        return {
            title: this.$route.meta.title || 'Relevent'
        };
    },
    computed: {
        backButton() {
            return this.$route.name !== "MyEvent" && this.$route.name !== "Scan" && this.$route.name !== "ScannerEvent";
        },

        themeColor() {
            return this.appDark ? '#272727' : 'white';
        }
    },

    watch: {
        '$route'() {
            this.title = this.$route.meta.title || 'Relevent';
        },
    }
}
</script>

<style lang="css" scoped>
.title {
    font-size: 1.125rem !important;
    font-weight: 700;
}

.rv-app-bar {
    border-bottom: 1px solid !important;
}

.theme--light .rv-app-bar {
    border-color: #ECECEC !important;
}

.theme--dark .rv-app-bar {
    border-color: rgba(255,255,255,0.12) !important;
}
</style>