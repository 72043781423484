export default {
  namespaced: true,
  state: {
    user: {},
    company: {},
    producer: false
  },
  mutations: {
    SET_USER(state, payload) { state.user = payload },
    SET_COMPANY(state, payload) { state.company = payload },
    SET_USER_NAME(state, payload) { state.user.displayName = payload },
    set_role_producer(state, payload) { state.producer = payload },
  },
};
