let app;
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { auth } from "./firebase"; // firebase
import { onAuthStateChanged } from "firebase/auth";
import moment from "moment";
moment.locale("es");

Vue.config.productionTip = false;

Vue.mixin({
  computed: {
    appMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    appDark() {
      return this.$vuetify.theme.dark;
    }
  }
});

function initVueApp() {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }
}

onAuthStateChanged(auth, async (userAuth) => {
  // Get user data
  if (userAuth) {
    const tokenResult = await userAuth.getIdTokenResult();

    // Get role
    const role = Object.fromEntries(
      Object.entries(tokenResult.claims).filter(([key]) =>
        ["admin", "owner", "seller", "scan"].includes(key)
      )
    );

    store.commit("auth/SET_USER", {
      companyId: tokenResult.claims.companyId,
      displayName: userAuth.displayName,
      email: userAuth.email,
      avatar: userAuth.photoURL,
      uid: userAuth.uid,
      role: Object.keys(role).toString(),
      ...role,
      roleName: tokenResult.claims.roleName,
    });

    if (tokenResult.claims.roleName === "producer") {
      store.commit("auth/set_role_producer", true);
    }
  } else {
    console.debug('auth/LOGOUT_USER')
  }

  // Initalize app
  initVueApp();
});
