<template>
    <v-list dense nav>
        <v-subheader>Eventos</v-subheader>
        <template v-for="item in items">
            <v-list-item :key="item.title" v-if="allowed(item.auth)" :to="item.to" link>
                <v-list-item-icon>
                    <span v-html="item.icon"></span>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>

        <v-subheader>Sistema</v-subheader>
        <template v-for="item in itemsConfig">
            <v-list-item :key="item.title" v-if="allowed(item.auth)" :to="item.to" link>
                <v-list-item-icon>
                    <span v-html="item.icon"></span>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
        <v-list-item @click="onClose" link>
            <v-list-item-icon>
                <span v-html="logoutApp.icon"></span>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>{{ logoutApp.title }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
import { signOutAction } from '@/api/auth';
import { iconHome, iconHistory, iconVip, iconBlock, iconTeam, iconRocket, iconInfo, iconLogout, iconUser } from '../../mixins/icons'

export default {
    data: () => ({
        items: [
            { title: 'Inicio', icon: iconRocket, to: { name: "owner-home" }, auth: ["owner"], },
            { title: 'Eventos', icon: iconHome, to: { name: "MyEvents" }, auth: ["seller"], },
            { title: 'Eventos Anteriores', icon: iconHistory, to: { name: "EventsHistory" }, auth: ["seller", 'owner'] },
            { title: 'Asistentes Especiales', icon: iconVip, to: { name: "attendees-specials" }, auth: ["owner"] },
            { title: 'Asistentes Bloqueados', icon: iconBlock, to: { name: "BlackList" }, auth: ["owner"] },
        ],

        itemsConfig: [
            { title: 'Team Empresa', icon: iconTeam, to: { name: "TeamView" }, auth: ["owner"] },
            { title: 'Información', icon: iconInfo, to: { name: "version" }, auth: ["owner", "seller", "scan"] },
            { title: 'Mi Cuenta', icon: iconUser, to: { name: "profile" }, auth: ["owner", "seller", "scan"] },
        ],

        logoutApp: { title: 'Cerrar Sesión', icon: iconLogout },
    }),

    // computed: {
    //     isMobile() {
    //         return this.$vuetify.breakpoint.mobile;
    //     },
    // },

    methods: {
        allowed(auth) {
            const role = this.$store.state.auth.user.role
            return auth.includes(role)
        },
        async onClose() {
            if (confirm("Seguro que quieres salir")) {
                await signOutAction()
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.theme--dark {

    .v-list-item--active {
        background-color: #5542f6;

        &::before {
            display: none;
        }
    }
}

.v-list-item__title {
    font-weight: 600 !important;
}
</style>