<template>
  <v-container fluid>

    <!-- pageHeader -->
    <v-row align="center" class="mb-4" dense>
      <v-col cols="12" md="8">
        <PageTitle>Equipo</PageTitle>
      </v-col>
      <v-col cols="12" md="4" class="text-end">
        <v-btn :block="appMobile" color="primary" class="px-6" large @click="dialog = true" depressed>
          Agregar Colaborador
        </v-btn>
      </v-col>
    </v-row>

    <!-- loading -->
    <div v-if="isLoading"> <v-skeleton-loader v-for="n in 10" :key="n" type="list-item-avatar-two-line" class="mb-3" />
    </div>

    <!-- card -->
    <v-card v-for="item, index in team" :key="index" class="mb-3" flat>
      <TeamItemList :data="item" :key="index" @remove="removeItem($event)" />
    </v-card>

    <TeamDialogAdd v-model="dialog" @success="addItem($event)" />
    <ToastNoti v-model="snackbar" :data="snackbarContent" />
  </v-container>
</template>

<script>
import { getAllTeam } from "@/api/team";
import TeamItemList from "./TeamItemList.vue";
import TeamDialogAdd from "./TeamAdd.vue";
import ToastNoti from "@/components/Toast.vue";
import PageTitle from '@/components/layout/PageTitle.vue';

export default {
  components: { TeamItemList, TeamDialogAdd, ToastNoti, PageTitle },

  data() {
    return {
      team: [],
      dialog: false,
      snackbar: false,
      isLoading: false,
      snackbarContent: { text: "", color: "" },
    };
  },

  methods: {
    async fetchItems() {
      try {
        this.isLoading = true;
        this.team = await getAllTeam();
      } catch (error) {
        console.error("Error: ", error);
      } finally {
        this.isLoading = false;
      }
    },

    addItem(item) {
      this.team.unshift(item);
      this.snackbar = true;
      this.snackbarContent = { text: "Colaborador creado con exito", color: "success" }
    },

    removeItem(userId) {
      const index = this.team.findIndex(user => user.uid === userId);
      if (index !== -1) { this.team.splice(index, 1) }
      this.snackbar = true;
      this.snackbarContent = { text: "Colaborador eliminado con exito", color: "error" }
    },
  },

  mounted() { this.fetchItems() },
};
</script>