<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("auth", ["user", "company"]),
    alertType() {
      return this.company.disabled ? 'error' : 'warning';
    },
    showAlert() {
      return this.user.owner && (this.company.warning || this.company.disabled);
    },
    alertMessage() {
      return this.company.warning
        ? '¡Atención! Su suscripción está por vencer.'
        : '¡Atención! Su suscripción está suspendida.';
    }
  }
}
</script>

<template>
  <v-alert v-if="showAlert" :type="alertType" dense class="rv-banner rounded-0 py-4 mb-1">
    <p class="mb-0">{{ alertMessage }}</p>
  </v-alert>
</template>

<style scoped lang="scss">
.rv-banner {
  p {
    font-size: 14px;
    font-weight: 600;
  }
}
</style>