<template>
    <div class="container">
        <v-row>
            <v-col cols="12" md="4" lg="3">
                <v-card tile flat>
                    <v-card-text class="text-center">
                        <UpdateImageProfile :userProfile="userProfile" :size="120" />
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="8" lg="9">
                <v-card tile flat>
                    <v-tabs color="black" v-model="tab">
                        <v-tab>Información</v-tab>
                        <v-tab>Password</v-tab>
                    </v-tabs>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <h3 class="text-h6 mb-3 font-weight-semibold text--primary">Información del Usuario</h3>
                                <v-form v-if="userProfile" ref="formPass" @submit.prevent>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field v-model="userProfile.displayName" label="Nombre" outlined
                                                hide-details></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field v-model="userProfile.email" label="Email" outlined disabled
                                                hide-details></v-text-field>
                                        </v-col>
                                        <v-col cols="12" class="text-end gap-3">
                                            <v-btn color="grey" class="mr-5" depressed outlined
                                                @click="cancelUpdateUserProfile" :disabled="loading">Cancelar</v-btn>
                                            <v-btn @click="updateUserProfile" :loading="loading" :disabled="loading"
                                                color="primary" depressed>Guardar Cambios</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-tab-item>
                            <v-tab-item>
                                <h3 class="text-h6 mb-3 font-weight-semibold text--primary">Cambiar contraseña</h3>
                                <v-form ref="form" @submit.prevent>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field v-model="password1" :rules="required" label="Contraseña"
                                                outlined hide-details></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field v-model="password2" :rules="required"
                                                label="Confirmar contraseña" outlined :error="!isPasswordValid"
                                                hide-details></v-text-field>
                                        </v-col>
                                        <v-col cols="12" class="text-end gap-3">
                                            <v-btn color="grey" class="mr-5" :disabled="loading" depressed outlined
                                                @click="cancelUpdatePassword">Cancelar</v-btn>
                                            <v-btn @click="updateUserPassword" :disabled="loading || !isPasswordValid"
                                                :loading="loading" color="primary" depressed>Actualizar
                                                Contraseña</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import mixins from '@/mixins/rules'
import { auth_get_user_profile, auth_update_user_profile, auth_update_user_password } from '@/services/userProfile'
import UpdateImageProfile from '@/components/UpdateImageProfile.vue'
export default {
    mixins: [mixins],
    components: {
        UpdateImageProfile
    },
    data() {
        return {
            tab: null,

            loading: false,
            userProfile: null,
            originalUserProfile: null,
            password1: '',
            password2: '',
        }
    },
    mounted() {
        this.getUserProfile()
    },
    methods: {
        async getUserProfile() {
            try {
                const userProfile = await auth_get_user_profile()
                this.userProfile = userProfile
                this.originalUserProfile = JSON.parse(JSON.stringify(userProfile))
            } catch (error) {
                console.error('Error al obtener el perfil del usuario', error)
            }
        },
        async updateUserProfile() {
            this.loading = true
            try {
                await auth_update_user_profile(this.userProfile)
            } catch (error) {
                console.error('Error al actualizar el perfil del usuario', error)
            } finally {
                this.loading = false
            }
        },
        async updateUserPassword() {
            if (!this.$refs.formPassword.validate()) return;

            this.loading = true
            try {
                await auth_update_user_password(this.password1)
            } catch (error) {
                console.error('Error al actualizar la contraseña del usuario', error)
            } finally {
                this.loading = false
            }
        },
        cancelUpdateUserProfile() {
            this.userProfile = JSON.parse(JSON.stringify(this.originalUserProfile))
        },
        cancelUpdatePassword() {
            this.password1 = ''
            this.password2 = ''
        }
    },
    computed: {
        isPasswordValid() {
            return this.password1 === this.password2
        }
    }
}
</script>